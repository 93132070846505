import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import axios from 'axios';
import "../styles.css";

const VinoPage = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const [activeSection, setActiveSection] = useState('');

  const sections = [
    { id: "1.-Domain-specific-area-and-objectives-of-the-project", title: "Introduction" },
    { id: "2.-Dataset-Description", title: "Dataset Description" },
    { id: "3.-Data-Preparation", title: "Data Preparation" },
    { id: "4.-Statistical-Analysis", title: "Statistical Analysis" },
    { id: "5.-Visualisation", title: "Visualisation" },
    { id: "6.-Building-The-Machine-Learning-Model", title: "Building The ML Model" },
    { id: "7.-Validation", title: "Validation" },
    { id: "8.-Feature-Engineering", title: "Feature Engineering" },
    { id: "9.-Evaluation-Of-The-Model", title: "Conclusion" },
  ];

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await axios.get(`${process.env.PUBLIC_URL}/wineQualityPredDark.html`);
        setHtmlContent(response.data);
      } catch (error) {
        console.error('Failed to load HTML content', error);
      }
    };

    fetchHtmlContent();

    const style = document.createElement('style');
    style.innerHTML = `
      [id] {
        scroll-margin-top: 90px;
      }
    `;
    document.head.appendChild(style);

    const handleScroll = () => {
      const sectionOffsets = sections.map(section => {
        const element = document.getElementById(section.id);
        return element ? element.offsetTop : null;
      });

      const scrollPosition = window.scrollY + window.innerHeight * 0.1;
      const activeIndex = sectionOffsets.findIndex((offset, index) => {
        return scrollPosition >= offset && (index === sections.length - 1 || scrollPosition < sectionOffsets[index + 1]);
      });

      if (activeIndex !== -1 && sections[activeIndex].id !== activeSection) {
        setActiveSection(sections[activeIndex].id);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeSection]);

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      const headerHeight = 90;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-200">
      <header className="px-10 py-3 flex items-center justify-between fixed top-0 left-0 right-0 z-20 bg-black">
        <div className="flex items-center">
          <Link to="/" className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors bg-gray-600/30 rounded-full px-4 py-2.5">
            <ArrowLeft size={16} />
            <span className="font-plex text-sm">Back</span>
          </Link>
        </div>
        <div className="text-center">
          <div className="font-space text-xl text-white font-bold">Wine Quality Analysis</div>
          <p className="font-plex text-sm gradient-text fade-in-text mt-2">The Secret to Crafting Top-Quality Wine</p>
        </div>
        <div style={{ width: 48 }}></div>
      </header>
      <div className="flex flex-1 px-8 pt-16">
        <main className="flex-1 flex justify-center items-start pr-50 pl-12">
          <div className="relative z-10 rounded-lg overflow-hidden bg-black shadow-lg w-full max-w-5xl mt-4">
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} className="overflow-auto h-full" />
          </div>
        </main>
        <aside className="w-48 pt-10 fixed top-12 right-32">
          <h3 className="font-plex text-xs font-medium uppercase tracking-wider text-gray-500 mb-6">
            C0NTENTS
          </h3>
          <nav>
            <ul className="space-y-4">
              {sections.map(section => (
                <li key={section.id}>
                  <a 
                    href={`#${section.id}`}
                    onClick={(e) => scrollToSection(e, section.id)}
                    className={`font-plex toc-link block transition-all ${activeSection === section.id ? 'text-white text-base' : 'text-gray-400/50 text-sm'}`}
                  >
                    {section.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
      </div>
    </div>
  );
};

export default VinoPage;
