import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import HomePage from './homePage';
import "../styles.css";

const LandingPage = () => {
  const fullText = "Welcome to cshilin.com";
  const [displayedText, setDisplayedText] = useState('');
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [showLanding, setShowLanding] = useState(false);
  const [animateHome, setAnimateHome] = useState(true);
  const [firstVisit, setFirstVisit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isFirstVisit = !sessionStorage.getItem('landingAnimationCompleted');
    
    if (isFirstVisit) {
      setFirstVisit(true);
      setShowLanding(true);
      setAnimateHome(false);
    }
  }, []);

  useEffect(() => {
    if (!firstVisit) return;
    
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < fullText.length) {
        setDisplayedText(fullText.slice(0, index + 1));
        index++;
      } else {
        clearInterval(typingInterval);
        setIsTypingComplete(true);
      }
    }, 50);
    
    return () => clearInterval(typingInterval);
  }, [firstVisit]);

  useEffect(() => {
    if (!firstVisit) return;
    
    if (isTypingComplete) {
      const timer = setTimeout(() => {
        setAnimateHome(true);
        setShowLanding(false);
        sessionStorage.setItem('landingAnimationCompleted', 'true');
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [isTypingComplete, firstVisit]);

  if (!firstVisit) {
    return <HomePage />;
  }

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'auto' }}>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: animateHome ? 0 : "100%" }}
        transition={{ duration: 1 }}
        style={{ position: 'absolute', width: '100%', height: '100%', top: 0 }}
      >
        <HomePage />
      </motion.div>
      
      <AnimatePresence>
        {showLanding && (
          <motion.div
            className="landing-page"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              backgroundColor: 'black',
              zIndex: 10,
            }}
            initial={{ y: 0 }}
            animate={{ y: 0 }}
            exit={{ y: "-100%", transition: { duration: 1 } }}
            key="landing"
          >
            <div className="relative flex items-center justify-center min-h-screen">
              <div className="text-center">
                <motion.h1
                  style={{ fontFamily: 'Martian Mono, monospace' }}
                  className="text-6xl font-bold text-white mb-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                >
                  {displayedText}
                  {!isTypingComplete && <span className="blinking-cursor"></span>}
                </motion.h1>
                {isTypingComplete && (
                  <motion.p
                    className="text-2xl text-gray-300"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 1 }}
                    style={{ fontFamily: 'IBM Plex Sans, sans-serif' }}
                  >
                    A work in progress
                  </motion.p>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LandingPage;