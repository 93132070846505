import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import "../styles.css";

const projects = [
  {
    id: 1,
    title: "Vino",
    description: "A deep dive into how different chemical properties affect wine quality, using data analysis and machine learning to uncover what makes a good wine.",
    tech: ["Python", "Pandas", "Scikit-learn", "Matplotlib", "Seaborn"],
    image: `${process.env.PUBLIC_URL}/winePhoto.webp`
  },
];

const HomePage = () => {

  const navigate = useNavigate();

  const handleProjectClick = (project) => {
    const projectPath = `/projects/${project.title.toLowerCase().replace(/ /g, '-')}`;
    navigate(projectPath);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    },
    hover: {
      scale: 1.08,
      y: -5,
      boxShadow: "0px 10px 20px rgba(255, 255, 255, 0.4)",
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <div className="min-h-screen bg-black text-white p-8 overflow-auto">
      <header className="mb-12 max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="flex flex-row items-center gap-6"
        >
          <img
            src={`${process.env.PUBLIC_URL}/profilePhoto.webp`}
            alt="Profile Photo"
            className="w-32 h-32 rounded-full object-cover border-2 border-gray-700"
          />
          <div>
            <h1 className="font-martian text-2xl font-bold text-white mb-2">Shi Lin Chong</h1>
            <p className="font-martian text-sm text-gray-400">Software Developer</p>
          </div>
        </motion.div>
      </header>

      <main className="max-w-6xl mx-auto">
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.6 }}
          className="mb-16"
        >
          <h2 className="font-plex text-xl font-light text-white opacity-40 mb-1">AB0UT</h2>
          <p className="font-plex text-lg font-text-gray-300 leading-relaxed">
            A passionate software engineer driven by the desire for continuous growth -- because we learn something new everyday.
            Be it picking up new technologies or figuring out how did the error occur, there's always something that tickles my brain everyday!
            I thrive on the satisfaction of debugging an error or even building an impactful solution for others.
            When I'm not debugging, you can catch me on occasional runs or scrolling on Reddit to
            see how people tackle everyday life and occasionally on wallstreetbets to see what's on the move.
          </p>
        </motion.section>

        <section>
          <h2 className="font-plex text-xl font-light text-white opacity-40 mb-4">PR0JECTS</h2>

          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {projects.map(project => (
              <motion.div
                key={project.id}
                className="cursor-pointer bg-gray-950 rounded-lg object-cover border-2 border-gray-500 overflow-hidden transition duration-0 "
                variants={itemVariants}
                whileHover="hover"
                onClick={() => handleProjectClick(project)}
              >
                <motion.img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6 cursor-pointer">
                  <h3 className="font-plex text-xl font-semibold mb-2">{project.title}</h3>
                  <p className="font-martian text-sm text-gray-400 mb-4">{project.description}</p>
                  <div className="flex flex-wrap gap-2">
                    {project.tech.map((tech, index) => (
                      <span
                        key={index}
                        className="font-plex text-xs bg-gray-800 text-gray-300 px-2 py-1 rounded"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </section>
      </main>
    </div>
  );
};

export default HomePage;